import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d4e1a64 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _1feba0e4 = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _00762992 = () => interopDefault(import('../pages/customers/index/create.vue' /* webpackChunkName: "pages/customers/index/create" */))
const _2dbb7bd2 = () => interopDefault(import('../pages/customers/index/_id/index.vue' /* webpackChunkName: "pages/customers/index/_id/index" */))
const _374f33f8 = () => interopDefault(import('../pages/employee-schedules/index.vue' /* webpackChunkName: "pages/employee-schedules/index" */))
const _6af64fd8 = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _7c2f83f2 = () => interopDefault(import('../pages/employees/index/create.vue' /* webpackChunkName: "pages/employees/index/create" */))
const _3a5d7702 = () => interopDefault(import('../pages/employees/index/_id/index.vue' /* webpackChunkName: "pages/employees/index/_id/index" */))
const _bec17340 = () => interopDefault(import('../pages/location-select.vue' /* webpackChunkName: "pages/location-select" */))
const _633604ad = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _0d3d3964 = () => interopDefault(import('../pages/locations/index/create.vue' /* webpackChunkName: "pages/locations/index/create" */))
const _95930f4a = () => interopDefault(import('../pages/locations/index/_id/index.vue' /* webpackChunkName: "pages/locations/index/_id/index" */))
const _091098de = () => interopDefault(import('../pages/locations/index/_id/edit.vue' /* webpackChunkName: "pages/locations/index/_id/edit" */))
const _1cb00b96 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _edba389e = () => interopDefault(import('../pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _21293b42 = () => interopDefault(import('../pages/prices/index/_id/index.vue' /* webpackChunkName: "pages/prices/index/_id/index" */))
const _14370700 = () => interopDefault(import('../pages/prices/index/_type/create.vue' /* webpackChunkName: "pages/prices/index/_type/create" */))
const _57b00d40 = () => interopDefault(import('../pages/printers/index.vue' /* webpackChunkName: "pages/printers/index" */))
const _d149c14a = () => interopDefault(import('../pages/printers/index/create.vue' /* webpackChunkName: "pages/printers/index/create" */))
const _7e19252e = () => interopDefault(import('../pages/printers/index/_id/index.vue' /* webpackChunkName: "pages/printers/index/_id/index" */))
const _041496ce = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _0ffcd588 = () => interopDefault(import('../pages/reports/index/_id/index.vue' /* webpackChunkName: "pages/reports/index/_id/index" */))
const _542e6fc5 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _5c46cd36 = () => interopDefault(import('../pages/services/index/create.vue' /* webpackChunkName: "pages/services/index/create" */))
const _5c55e573 = () => interopDefault(import('../pages/services/index/_id/index.vue' /* webpackChunkName: "pages/services/index/_id/index" */))
const _4c816078 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _1418c057 = () => interopDefault(import('../pages/subscription/index/create.vue' /* webpackChunkName: "pages/subscription/index/create" */))
const _d08cd17a = () => interopDefault(import('../pages/terminals.vue' /* webpackChunkName: "pages/terminals" */))
const _0225c0a8 = () => interopDefault(import('../pages/timesheets/index.vue' /* webpackChunkName: "pages/timesheets/index" */))
const _b267f844 = () => interopDefault(import('../pages/employee-schedules/index2.vue' /* webpackChunkName: "pages/employee-schedules/index2" */))
const _431ebf31 = () => interopDefault(import('../pages/pos/new.vue' /* webpackChunkName: "pages/pos/new" */))
const _2800571e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0d6114de = () => interopDefault(import('../pages/pos/_customer/index.vue' /* webpackChunkName: "pages/pos/_customer/index" */))
const _5ce41baa = () => interopDefault(import('../pages/terminals' /* webpackChunkName: "" */))
const _2d7ac027 = () => interopDefault(import('../pages/pos/_customer/pay.vue' /* webpackChunkName: "pages/pos/_customer/pay" */))
const _3a0646c4 = () => interopDefault(import('../pages/pos/_customer/pay/payment.vue' /* webpackChunkName: "pages/pos/_customer/pay/payment" */))
const _5865eabe = () => interopDefault(import('../pages/pos/_customer/payments.vue' /* webpackChunkName: "pages/pos/_customer/payments" */))
const _659b9e66 = () => interopDefault(import('../pages/pos/_customer/pickup.vue' /* webpackChunkName: "pages/pos/_customer/pickup" */))
const _2e7bf934 = () => interopDefault(import('../pages/pos/_customer/tickets.vue' /* webpackChunkName: "pages/pos/_customer/tickets" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/change-password",
    component: _4d4e1a64,
    name: "change-password"
  }, {
    path: "/customers",
    component: _1feba0e4,
    name: "customers",
    children: [{
      path: "create",
      component: _00762992,
      name: "customers-index-create"
    }, {
      path: ":id",
      component: _2dbb7bd2,
      name: "customers-index-id"
    }]
  }, {
    path: "/employee-schedules",
    component: _374f33f8,
    name: "employee-schedules"
  }, {
    path: "/employees",
    component: _6af64fd8,
    name: "employees",
    children: [{
      path: "create",
      component: _7c2f83f2,
      name: "employees-index-create"
    }, {
      path: ":id",
      component: _3a5d7702,
      name: "employees-index-id"
    }]
  }, {
    path: "/location-select",
    component: _bec17340,
    name: "location-select"
  }, {
    path: "/locations",
    component: _633604ad,
    name: "locations",
    children: [{
      path: "create",
      component: _0d3d3964,
      name: "locations-index-create"
    }, {
      path: ":id",
      component: _95930f4a,
      name: "locations-index-id"
    }, {
      path: ":id/edit",
      component: _091098de,
      name: "locations-index-id-edit"
    }]
  }, {
    path: "/login",
    component: _1cb00b96,
    name: "login"
  }, {
    path: "/prices",
    component: _edba389e,
    name: "prices",
    children: [{
      path: ":id",
      component: _21293b42,
      name: "prices-index-id"
    }, {
      path: ":type/create",
      component: _14370700,
      name: "prices-index-type-create"
    }]
  }, {
    path: "/printers",
    component: _57b00d40,
    name: "printers",
    children: [{
      path: "create",
      component: _d149c14a,
      name: "printers-index-create"
    }, {
      path: ":id",
      component: _7e19252e,
      name: "printers-index-id"
    }]
  }, {
    path: "/reports",
    component: _041496ce,
    name: "reports",
    children: [{
      path: ":id",
      component: _0ffcd588,
      name: "reports-index-id"
    }]
  }, {
    path: "/services",
    component: _542e6fc5,
    name: "services",
    children: [{
      path: "create",
      component: _5c46cd36,
      name: "services-index-create"
    }, {
      path: ":id",
      component: _5c55e573,
      name: "services-index-id"
    }]
  }, {
    path: "/subscription",
    component: _4c816078,
    name: "subscription",
    children: [{
      path: "create",
      component: _1418c057,
      name: "subscription-index-create"
    }]
  }, {
    path: "/terminals",
    component: _d08cd17a,
    name: "terminals"
  }, {
    path: "/timesheets",
    component: _0225c0a8,
    name: "timesheets"
  }, {
    path: "/employee-schedules/index2",
    component: _b267f844,
    name: "employee-schedules-index2"
  }, {
    path: "/pos/new",
    component: _431ebf31,
    name: "pos-new"
  }, {
    path: "/",
    component: _2800571e,
    name: "index"
  }, {
    path: "/pos/:customer",
    component: _0d6114de,
    name: "pos-customer"
  }, {
    path: "/terminals/:slug",
    component: _5ce41baa,
    name: "terminals-slug"
  }, {
    path: "/pos/:customer?/pay",
    component: _2d7ac027,
    name: "pos-customer-pay",
    children: [{
      path: "payment",
      component: _3a0646c4,
      name: "pos-customer-pay-payment"
    }]
  }, {
    path: "/pos/:customer?/payments",
    component: _5865eabe,
    name: "pos-customer-payments"
  }, {
    path: "/pos/:customer?/pickup",
    component: _659b9e66,
    name: "pos-customer-pickup"
  }, {
    path: "/pos/:customer?/tickets",
    component: _2e7bf934,
    name: "pos-customer-tickets"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
